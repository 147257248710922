/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/camelcase */
import { ClientTemplate, Component } from '@/components/ClientTemplate';
import {
  LoginPageTemplate, Pages, LoginRequestPayload,
} from '@/types';
import { isForm, mapFormToObject } from '@/helpers/dom';
import { TidSDKAuthParams, TidSDKUIParams } from '@/types/tidSdk';

declare global {
  interface Window { PasswordCredential?: new (htmlFormElement: HTMLFormElement) => Credential}
}

@Component
export default class ClientLogin extends ClientTemplate<Pages.Login> {
  passwordIsHidden = true;

  readonly extAuth2ContainerId = 'extauth2-container';

  readonly extAuth2ScriptSrc = 'https://sso-forms-prod.cdn-tinkoff.ru/tinkoff-id/widget.js';

  get settings() {
    return this.store.getSettings();
  }

  get hasLoginWithLink() {
    const startData = this.session.getStartData();
    if (startData) {
      return startData.extauth1_is_enable;
    }
    return false;
  }

  get registrationLink() {
    if (this.settings) {
      const { client_reg_uri } = this.settings;
      if (client_reg_uri) return client_reg_uri;
    }
    return undefined;
  }

  async login(element: Element) {
    this.error = null;
    if (isForm(element)) {
      const payload = mapFormToObject<LoginRequestPayload>(element);
      const result = await this.pds.login(payload);
      if (result.isFailure()) {
        this.error = result.error;
      }
      return result;
    }
    throw new Error('Argument must be HTMLFormElement');
  }

  // eslint-disable-next-line consistent-return, class-methods-use-this
  saveCredentials(element: Element) {
    if (window.PasswordCredential && isForm(element)) {
      try {
        const credential = new window.PasswordCredential(element);
        return navigator.credentials.store(credential);
      } catch (e) {
        console.warn(e);
      }
    }
  }

  async loginWith() {
    const result = await this.pds.getLoginWithLink();
    if (result.isSuccess()) {
      this.router.changePage(result);
    } else {
      this.error = result.error;
    }
  }

  loadExtAuth2Script(context: Window) {
    return new Promise((resolve, reject) => {
      const script = context.document.createElement('script');
      script.src = this.extAuth2ScriptSrc;
      context.document.head.appendChild(script);
      script.onload = () => resolve();
      script.onerror = () => reject();
    });
  }

  initTid(context: Window) {
    const startData = this.session.getStartData();

    const authParams: TidSDKAuthParams = {
      redirectUri: startData?.extauth2_redirect_uri ?? '',
      responseType: 'code',
      clientId: startData?.extauth2_client_id ?? '',
      state: startData?.extauth2_state ?? '',
    };

    const uiParams: TidSDKUIParams = {
      container: `#${this.extAuth2ContainerId}`,
      size: 'm',
      color: 'primary',
      target: '_parent',
    };

    const tidSdk = new context.TidSDK(authParams);
    tidSdk.addButton(uiParams);
  }

  build() {
    const data: LoginPageTemplate = {
      showPasswordLink: this.actions.create(() => {
        this.passwordIsHidden = !this.passwordIsHidden;
      }),
      passwordIsHidden: this.passwordIsHidden,
      resetPasswordLink: this.actions.create(() => {
        this.router.goTo(Pages.SendResetPasswordCode);
      }),
      loginLink: this.actions.create((element) => {
        this.login(element)
          .then((result) => {
            if (result) {
              if (result.isSuccess()) {
                this.saveCredentials(element);
              }
              return result;
            }
            throw new Error('Failed to save credentials');
          })
          .then((result) => {
            if (result.isSuccess()) {
              this.router.changePage(result);
            }
          });
      }),
    };
    if (this.hasLoginWithLink) {
      data.loginWithLink = this.actions.create(() => {
        this.loginWith();
      });
    }
    if (this.session.getStartData()?.extauth2_is_enable) {
      data.loginWith2ContainerId = this.extAuth2ContainerId;
    }
    if (this.registrationLink) {
      data.registrationLink = this.actions.create(() => {
        if (this.registrationLink) {
          window.location.assign(this.registrationLink);
        }
      });
    }
    return [Pages.Login, data] as const;
  }

  async onFrameLoad(context: Window) {
    if (this.session.getStartData()?.extauth2_is_enable) {
      await this.loadExtAuth2Script(context);
      this.initTid(context);
    }
  }
}
